import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./admin.module.css";
import { Link, useHistory } from "react-router-dom";
import { getAccounts, setAccount } from "services/adminApi.service.js";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Avatar,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import InputSearch from "components/util/inputSearch/inputSearch";
import SortableTableHead from "components/util/sortableTableHead/sortableTableHead";
import Button from "components/util/button/button";
import { debounce } from "util/helpers";

const Admin = ({
  onSnackbar,
  isInternalUser,
  isSuperAdmin,
  changeAccount,
  accountInfo,
}) => {
  const history = useHistory();
  const [accounts, setAccounts] = useState(null);
  const [activeColumn, setActiveColumn] = useState(undefined);
  const [activeSortDirection, setActiveSortDirection] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hasSorted, setHasSorted] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [isDeleteValid, setIsDeleteValid] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setIsDeleteValid(deleteConfirmation === accountToDelete?.name);
  }, [deleteConfirmation, accountToDelete]);

  function displayDeleteModal(show, account) {
    setShowDeleteModal(show);
    setAccountToDelete(account);
    setDeleteConfirmation("");
  }

  async function fetchAccounts(
    search,
    offset,
    limit,
    sortBy = "name",
    sortDirection = "asc"
  ) {
    setLoading(true);
    try {
      const data = await getAccounts(offset, limit, sortBy, sortDirection, search);
      setAccounts(data.rows);
      setCount(data.count);
    } catch {
      setAccounts([]);
      onSnackbar("error", `Failed to fetch accounts`);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAccounts(searchTerm, offset, rowsPerPage);
  }, []);

  const debouncedSearch = debounce((search) => {
    setSearchTerm(search);
    setOffset(0);
    fetchAccounts(
      search,
      0,
      rowsPerPage,
      activeColumn,
      activeSortDirection > 0 ? "asc" : "desc"
    );
  });

  function changePage(event, newPage) {
    const newOffset = newPage * rowsPerPage;
    setOffset(newOffset);
    fetchAccounts(
      searchTerm,
      newOffset,
      rowsPerPage,
      activeColumn,
      activeSortDirection > 0 ? "asc" : "desc"
    );
  }

  function changeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    fetchAccounts(
      searchTerm,
      offset,
      event.target.value,
      activeColumn,
      activeSortDirection > 0 ? "asc" : "desc"
    );
  }

  function sortAccounts(value, direction) {
    setActiveColumn(value);
    setActiveSortDirection(direction);
    fetchAccounts(searchTerm, offset, rowsPerPage, value, direction > 0 ? "asc" : "desc");
    setHasSorted(true);
  }

  async function toggleAccountSubscription(account, $event) {
    try {
      if ($event) {
        $event.stopPropagation();
      }

      const newAccounts = [...accounts];
      account.active = !account.active;
      const pauseDate = account.active ? null : new Date();
      account.pauseDate = pauseDate;

      await setAccount(account.id, { active: account.active, pauseDate });
      setAccounts(newAccounts);
      onSnackbar("success", `Account is ${account.active ? "active" : "inactive"}`);
    } catch (e) {
      console.error(e);
      onSnackbar("error", `Failed to toggle account`);
    }
  }

  function openChurnModal($event, account) {
    $event.stopPropagation();

    if (!account) {
      onSnackbar("error", `Account not found`);
      return;
    }

    // Open modal
    displayDeleteModal(true, account);
  }

  async function handleDeleteAccount() {
    try {
      setDeleting(true);
      await toggleAccountSubscription(accountToDelete);
      displayDeleteModal(false, null);
      setDeleting(false);
    } catch {
      setDeleting(false);
      onSnackbar("error", `Failed to delete account`);
    }
  }

  return (
    <div className={styles.admin}>
      <div className={styles.header}>
        <div>
          <h1>Super Admin Portal</h1>
          <p>Select the organization name or landing page you would like to view.</p>
        </div>
        <div className={styles.tools}>
          <InputSearch onSearch={debouncedSearch} />
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/a/${accountInfo.id}/admin/accounts/new`}>
            <i className="material-icons">add</i>
            Deploy
          </Button>
        </div>
      </div>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress size="80px" />
        </div>
      )}
      {!loading && (
        <>
          <Table size="small" className={styles.fixedTable}>
            <SortableTableHead
              columns={[
                { name: "Organization Logo", value: "logo", sortable: false },
                { name: "Organization Name", value: "name", sortable: true },
                { name: "Status", value: "active", sortable: true },
              ]}
              activeColumn={activeColumn}
              activeSortDirection={activeSortDirection}
              onClick={sortAccounts}
              hasSorted={hasSorted}
            />
            <TableBody>
              {accounts?.length > 0 &&
                accounts.map((account) => {
                  const { id, logo, name, active, stripeId } = account;

                  return (
                    <TableRow
                      className={styles.tableRow}
                      onClick={() =>
                        history.push(`/a/${accountInfo.id}/admin/accounts/${id}`)
                      }
                      key={id}>
                      <TableCell>
                        <Link
                          to={`/a/${accountInfo.id}/admin/accounts/${id}`}
                          onClick={(e) => e.stopPropagation()}>
                          <Avatar>
                            <img
                              className={styles.avatar}
                              src={logo || "/static/img/account.svg"}
                              alt="Logo"
                              loading="lazy"
                            />
                          </Avatar>
                        </Link>
                      </TableCell>
                      <TableCell>{name || "N/A"}</TableCell>
                      <TableCell>{active ? "Active" : "Inactive"}</TableCell>
                      {stripeId && (
                        <TableCell title="Account subscription managed by stripe">
                          <img
                            src="/static/img/logos/stripeLogoBlue.svg"
                            alt="Stripe Logo"
                          />
                        </TableCell>
                      )}
                      {!stripeId && <TableCell></TableCell>}
                      <TableCell>
                        <div className={styles.toolbar}>
                          {isInternalUser && (
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                changeAccount(id, name);
                              }}
                              variant="outlined"
                              size="small">
                              View Account
                            </Button>
                          )}
                          <Button
                            href={`/a/${accountInfo.id}/admin/accounts/${id}/users`}
                            onClick={(e) => e.stopPropagation()}
                            variant="outlined"
                            size="small">
                            User Setup
                          </Button>

                          <IconButton
                            onClick={($event) =>
                              active
                                ? openChurnModal($event, account)
                                : toggleAccountSubscription(account, $event)
                            }
                            aria-label="delete"
                            size="small"
                            title={
                              active
                                ? "Delete Account (Permanently Deletes Data, Superadmins Only)"
                                : "Reactivate Account"
                            }
                            disabled={!isSuperAdmin}
                            className={styles.deleteButton}>
                            <i className="material-icons">
                              {active ? "delete_outline" : "play_arrow"}
                            </i>
                          </IconButton>
                          <i className={`material-icons ${styles.chevron}`}>
                            chevron_right
                          </i>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={Math.floor(offset / rowsPerPage)}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
          />
          {isSuperAdmin && (
            <Dialog
              maxWidth="md"
              open={showDeleteModal}
              onClose={() => displayDeleteModal(false, null)}>
              <DialogTitle>Delete {accountToDelete?.name || "Account"}</DialogTitle>
              <DialogContent>
                <p>
                  Deleting this account will result in{" "}
                  <strong>permanent data loss</strong> and is intended to be used for
                  accounts that are no longer needed. Accounts will be retained for a
                  period of time before being destroyed. Are you sure you want to delete
                  this account?
                </p>
                <br />
                <p className={styles.deleteAlertBlock}>
                  <i className={`material-icons ${styles.errorIcon}`}>error_outline</i>
                  <strong>
                    Data loss is non-recoverable, Please enter the account name &quot;
                    {accountToDelete?.name || "Account"}&quot; to confirm.
                  </strong>
                </p>
                <br />

                <TextField
                  variant="outlined"
                  size="small"
                  label="Account Name"
                  required
                  name="name"
                  placeholder={accountToDelete?.name || "Account"}
                  value={deleteConfirmation}
                  onChange={($event) => setDeleteConfirmation($event.target.value)}
                  autoComplete="off"
                />
              </DialogContent>

              <DialogActions>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => displayDeleteModal(false, null)}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isDeleteValid || deleting}
                  title={
                    isDeleteValid
                      ? "Permanently delete account"
                      : "Account confirmation much match the account name"
                  }
                  onClick={handleDeleteAccount}>
                  {deleting ? "Deleting..." : "Delete"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

Admin.propTypes = {
  onSnackbar: PropTypes.func.isRequired,
  isInternalUser: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  changeAccount: PropTypes.func,
  accountInfo: PropTypes.object,
};

export default Admin;
