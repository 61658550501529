import React, { useState } from "react";
import PropTypes from "prop-types";
import ShowProperties from "../../../util/contacts/showProperties";
import MapColumns from "../../contacts/addContact/bulk/map/mapColumns";
import UploadFile from "../../contacts/addContact/bulk/uploadFile";
import { Stepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import ConfirmAndAdd from "./confirmAndAdd";
import bulkRequestProperties from "../../../util/data/bulkRequestProperties";
import arrayToCsv from "../../../util/contacts/arrayToCsv";

export default function AddBulkStepper(props) {
  const [properties, setProperties] = useState(bulkRequestProperties);
  const [csvData, setCsvData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Prepare File", "Map and Send", "Done"];

  return (
    <div className="row col-12" style={{ marginTop: "20px" }}>
      <Stepper
        activeStep={activeStep}
        className="col-12"
        style={{ backgroundColor: "#FAFAFA" }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div className="row col-12 pagePadding">
        {activeStep === 0 && (
          <>
            <ShowProperties properties={properties} id="bulkImportVideoRequest" />
            <UploadFile
              properties={properties}
              csvData={csvData}
              setCsvData={setCsvData}
              setActiveStep={setActiveStep}
              setProperties={setProperties}
              hideUploadWarning={true}
              validations={[
                (rows) =>
                  rows.length > 500 &&
                  "Max upload exceeded. No more than 500 videos can be requested at once. No request were saved. Please create separate files and try again.",
              ]}
              accountInfo={props.accountInfo}
            />
            <div className="bulkUploadActionBar" style={{ justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!csvData?.rows}
                onClick={() => setActiveStep(1)}>
                Next
                <i className="material-icons">arrow_forward</i>
              </Button>
            </div>
          </>
        )}

        {activeStep === 1 && (
          <>
            <MapColumns
              csvData={csvData}
              setProperties={setProperties}
              properties={properties}
              calledFromBulkRequest={true}
            />
            <ConfirmAndAdd
              accountInfo={props.accountInfo}
              setActiveStep={setActiveStep}
              setProperties={setProperties}
              properties={properties}
              csvData={csvData}
              setCsvData={setCsvData}
              userIsAdmin={props.userIsAdmin}
            />
          </>
        )}

        {activeStep === 2 && (
          <>
            <div className="row col-12" style={{ marginTop: "25px" }}>
              <div className="col-12 justify-content-center align-items-start">
                <i
                  className="material-icons"
                  style={{ marginRight: "10px", color: "green", fontSize: "60px" }}>
                  check_circle
                </i>
              </div>
            </div>
            <div className="col-12">
              <h2 style={{ textAlign: "center", fontSize: "35px", color: "green" }}>
                Success!
              </h2>
              <p
                style={{
                  textAlign: "center",
                  padding: "20px",
                }}>{`${csvData.rows.length} requests successfully created. You can now close this page. TIP: Use the task template file 'taskTemplate.csv' to associate these videos with a new list of tasks.`}</p>
            </div>

            <div
              className="col-12"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "25px",
              }}>
              <Button
                onClick={() => arrayToCsv(csvData.rows, "taskTemplate.csv")}
                variant="contained"
                color="primary">
                Download task template
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

AddBulkStepper.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool,
};
