import React, { useState } from "react";
import { Button } from "@material-ui/core";
import TextInputToAPI from "../../../../../util/hooks/input/textInputToApi";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import { requestVideos } from "services/api.service";
import timeSinceDate from "components/util/functions/timeSinceDate";

export default function SendTextEmail(props) {
  let { shortLinkUrl, requestEmail, requestCell, recorderName, requestDirections } =
    props;
  let [state, setState] = useState({});
  let [sendEmailClickedOnce, setSendEmailClickedOnce] = useState(false);
  let [sendTextClickedOnce, setSendTextClickedOnce] = useState(false);

  let sendEmailOrText = async (channel) => {
    try {
      if (requestDirections === "" || !requestDirections) {
        throw new Error("Please include directions");
      }

      if (channel === "text") {
        if (requestCell === "" || !requestCell) {
          throw new Error("Please add recorder cell number");
        }

        setState({ textButtonState: "loading" });
      } else {
        if (requestEmail === "" || !requestEmail) {
          throw new Error("Please add recorder email");
        }

        setState({ emailButtonState: "loading" });
      }

      await requestVideos([
        {
          videoId: props.videoId,
          shareViaEmail: channel !== "text",
          shareViaText: channel === "text",
        },
      ]);

      if (channel === "text") {
        setState({ textButtonState: "saved" });
      } else {
        setState({ emailButtonState: "saved" });
      }
    } catch (err) {
      let errorMessage = err?.data?.message ?? err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  let mailBody =
    "Hi " +
    (recorderName || "") +
    ",%0d%0a%0d%0aI need your help recording a video. " +
    (requestDirections || "") +
    "%0d%0a%0d%0aClick here to record/upload your video: " +
    shortLinkUrl;
  let mailToLink =
    "mailto:" + (requestEmail || "") + "?subject=Video Request&body=" + mailBody;

  let resetSendButton = () => {
    setState({});
    setSendEmailClickedOnce(false);
    setSendTextClickedOnce(false);
  };

  const emailRequestHistory =
    props.requestHistory
      ?.filter((history) => history.shareViaEmail)
      .map(({ dateCreated, requestEmail }, index) => (
        <div key={index} style={{ color: "grey" }}>
          <i className="material-icons" style={{ fontSize: "14px", marginRight: "3px" }}>
            history
          </i>
          Sent email {timeSinceDate(dateCreated)} to {requestEmail}
        </div>
      )) ?? [];

  const textRequestHistory =
    props.requestHistory
      ?.filter((history) => history.shareViaText)
      .map(({ dateCreated, requestCell }, index) => (
        <div key={index} style={{ color: "grey" }}>
          <i className="material-icons" style={{ fontSize: "14px", marginRight: "3px" }}>
            history
          </i>
          Sent text {timeSinceDate(dateCreated)} to {requestCell}
        </div>
      )) ?? [];

  return (
    <>
      <div className="col-12 row" style={{ marginTop: "10px" }}>
        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12" style={{ marginTop: "15px" }}>
          <TextInputToAPI
            initialValue={props.requestEmail}
            label="Recorder Email"
            variant="outlined"
            field="requestEmail"
            apiCall={props.onUpdateVideo}
            multiline={false}
            startIcon="email"
          />
        </div>

        {emailRequestHistory.length > 0 && <div>{emailRequestHistory}</div>}

        <div className="col-12" style={{ marginTop: "10px" }}>
          <a href={mailToLink} target="_blank" rel="noopener noreferrer">
            <Button size="small" variant="contained" color="primary">
              Send From My Email
            </Button>
          </a>
          <div style={{ marginTop: "10px" }}>
            <span style={{ marginRight: "5px" }}>or</span>
            {sendEmailClickedOnce ? (
              <LoadingButton
                size="small"
                variant="contained"
                color="secondary"
                buttonState={state.emailButtonState}
                savedLabel={"Sent!"}
                label={"Click to Send"}
                clickedRegularButton={() => sendEmailOrText("email")}
                clickedSavedButton={resetSendButton}
              />
            ) : (
              <Button
                size="small"
                variant="outlined"
                color="default"
                onClick={() => setSendEmailClickedOnce(true)}>
                Send From Gratavid
              </Button>
            )}
          </div>
        </div>

        <div className="col-12" style={{ marginTop: "35px" }}>
          <TextInputToAPI
            initialValue={props.requestCell}
            label="Recorder Cell Number"
            variant="outlined"
            field="requestCell"
            apiCall={props.onUpdateVideo}
            multiline={false}
            startIcon="sms"
          />
        </div>

        {textRequestHistory.length > 0 && <div>{textRequestHistory}</div>}

        <div className="col-12" style={{ margin: "10px 0px 20px 0px" }}>
          {sendTextClickedOnce ? (
            <LoadingButton
              size="small"
              variant="contained"
              color="secondary"
              buttonState={state.textButtonState}
              savedLabel={"Sent!"}
              label={"Click to Send"}
              clickedRegularButton={() => sendEmailOrText("text")}
              clickedSavedButton={resetSendButton}
            />
          ) : (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setSendTextClickedOnce(true)}>
              Send Text
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
