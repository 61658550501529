import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { csvToObject } from "util/csv";
import { downloadFile } from "util/download";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./uploadFile.module.css";

const MAX_FILE_SIZE = 209_715_200;

export default function UploadFile(props) {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phiDisclaimerAcknowledged, setPhiDisclaimerAcknowledged] = useState(
    !props.accountInfo.isHealthcareOrg
  );

  const downloadTemplate = () => {
    let csvContent = "";
    for (const value of Object.values(props.properties)) {
      if (csvContent !== "") {
        csvContent += ",";
      }
      csvContent += value.columnHeader;
    }

    csvContent += "\r\n";
    downloadFile(csvContent, "template.csv");
  };

  let uploadFileNow = async (e) => {
    setErrorMessage("");

    try {
      let file = e.target.files[0];
      if (file) {
        let fileName = file.name;
        let blobSize = file.size;

        if (blobSize > MAX_FILE_SIZE) {
          throw new Error("File is too large. Must be smaller than 200mb.");
        }

        setLoading(true);

        const reader = new FileReader();
        reader.onload = (event) => {
          const csvString = event.target.result;
          const result = csvToObject(csvString, { quotes: true, quoteChar: '"' });
          const rows = result.data;
          const errors = result.errors;
          const headers = result.meta.fields;
          try {
            if (rows.length <= 0) {
              throw new Error("CSV file is empty, or using incorrect format");
            }

            if (props.validations) {
              props.validations.forEach((test) => {
                const error = test(rows);
                if (error) {
                  throw new Error(error);
                }
              });
            }

            props.setCsvData({ fileName, rows, errors, headers });
            props.setActiveStep(1);
          } catch (error) {
            setErrorMessage(error.message);
          }
        };

        reader.readAsText(file);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  let clearUploadFile = () => {
    //reset properties. if a user selects a column for "cellNumber",
    //uploads files, then reuploads a new file cellNumber our propertise will still show that mapped unless we reset it here
    let tempNewProperties = {};
    let propertiesKeys = Object.keys(props.properties);
    propertiesKeys.forEach((key) => {
      let current = props.properties[key];
      current.mapped = false;
      tempNewProperties[key] = current;
    });

    props.setProperties(tempNewProperties);
    props.setCsvData({});
  };

  const inputStyle = {
    uploadInput: {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: "100%",
      opacity: 0,
    },
  };

  let { csvData } = props;

  return (
    <>
      <div className="row col-12" style={{ marginTop: "15px" }}>
        <div className="col-12 row" style={{ padding: "10px 0px", marginTop: "10px" }}>
          <h2>2) Add your file</h2>
          <button
            className="link-button"
            style={{ marginLeft: "10px" }}
            onClick={() => downloadTemplate()}>
            Download file template (optional)
          </button>

          {!props.hideUploadWarning && (
            <div className="notificationMessageBox">
              <span>
                A contact is created based on its email address. Every email address you
                provide will create a unique contact. If any imported emails are already
                in your account, we will update their contact information with the data
                from your import.
              </span>
            </div>
          )}

          {props.accountInfo.isHealthcareOrg && (
            <div className={styles.phiNotificationMessageBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={phiDisclaimerAcknowledged}
                    onChange={() =>
                      setPhiDisclaimerAcknowledged(!phiDisclaimerAcknowledged)
                    }
                  />
                }
                label="I/We hereby certify that the contacts list being uploaded, does not contain Personal Healthcare Information (PHI).  I/We confirm that if any type of PHI is accidentally or inadvertently  uploaded onto the platform, I/We will immediately update and alert to this effect and ensure that all necessary measures are taken in order to remove such PHI from the platform."
              />
            </div>
          )}
        </div>

        {csvData.rows ? (
          <Button variant="outlined" onClick={clearUploadFile}>
            <span>{csvData.fileName}</span>
            <i className="material-icons" style={{ marginLeft: "5px" }}>
              cancel
            </i>
          </Button>
        ) : (
          <>
            {isLoading ? (
              <div className="col-12" style={{ padding: "20px" }}>
                <LinearProgress />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!phiDisclaimerAcknowledged}>
                <i className="material-icons" style={{ marginRight: "10px" }}>
                  file_upload
                </i>
                <span>Upload File</span>
                <input
                  type="file"
                  accept=".csv"
                  id="uploadFile"
                  style={inputStyle.uploadInput}
                  onClick={(e) => (e.target.value = null)}
                  onChange={uploadFileNow}
                />
              </Button>
            )}
          </>
        )}

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </div>
      <div className="col-12" style={{ height: "70px" }} />
    </>
  );
}

UploadFile.propTypes = {
  properties: PropTypes.object.isRequired,
  csvData: PropTypes.shape({
    rows: PropTypes.array,
    fileName: PropTypes.string,
  }).isRequired,
  setCsvData: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setProperties: PropTypes.func.isRequired,
  hideUploadWarning: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.func),
};
