import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./accountForm.module.css";
import * as Yup from "yup";

import Logo from "./logo";
import Colors from "./colors";
import AccountDetailsForm from "./accountDetailsForm";
import SsoSegment from "./ssoSegment";
import RadioSegment from "./radioSegment";

import Button from "components/util/button/button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createAccount, setAccount } from "services/adminApi.service";
import { createPendingAccount } from "services/api.service";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Description from "./description.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller } from "react-hook-form";
const MAX_INT32 = Math.pow(2, 31) - 1;

const userLimits = [
  { label: "3", value: "3" },
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "100", value: "100" },
  { label: "1k", value: "1000" },
  { label: "Unlimited", value: MAX_INT32.toString() },
];

const AccountForm = ({
  pendingAccountId,
  accountUID,
  accountInfo,
  isEditMode,
  onComplete,
  onSnackbar,
}) => {
  const newAccountLabels = {
    orgName: {
      label: "Account Name",
      required: true,
    },
    website: {
      label: "Website",
      required: !pendingAccountId,
    },
    firstName: {
      label: "Admin First Name",
      hideInEditMode: true,
      required: !isEditMode,
    },
    lastName: {
      label: "Admin Last Name",
      hideInEditMode: true,
      required: !isEditMode,
    },
    email: {
      label: "Admin Email Address",
      hideInEditMode: true,
      required: !isEditMode,
    },
    street: {
      label: "Street Address",
      required: true,
    },
    cityStateProvinceZip: {
      label: "City/State/Province/Zip Code",
      required: true,
    },
    country: {
      label: "Country",
      required: true,
    },
  };

  const constituentLimit = (accountInfo?.contactLimit || 3000).toString();
  const constituentLimits = [
    { label: "3K", value: "3000" },
    { label: "10K", value: "10000" },
    { label: "30K", value: "30000" },
    { label: "50K", value: "50000" },
    { label: "100K", value: "100000" },
    { label: "Unlimited", value: MAX_INT32.toString() },
    {
      label: `Custom: ${constituentLimit}`,
      type: "numberInput",
      value: "custom",
    },
  ];
  const isCurrentConstituentLimit = constituentLimits.some(
    (limit) => limit.value === constituentLimit
  );
  // react-hook-form setup
  let initialValues = {
    orgName: accountInfo?.name || "",
    website: accountInfo?.website || "",
    firstName: "",
    lastName: "",
    email: "",
    street: accountInfo?.address1 || "",
    cityStateProvinceZip: accountInfo?.address2 || "",
    country: accountInfo?.address3 || "",
    imageSrc: accountInfo?.logo || "/static/img/icon_padded.jpg",
    primaryColor: accountInfo?.primaryColor || "#E5004C",
    secondaryColor: accountInfo?.secondaryColor || "#FFCCBA",
    constituentLimit: isCurrentConstituentLimit ? constituentLimit : "custom",
    isVideoStorageUnlimited: "false",
    emailLimit: "1000",
    hasUnlimitedTemplates: "false",
    userLimit: (accountInfo?.userLimit || 3).toString(),
    hasVideoRequestingFeature: "false",
    ssos: [
      {
        name: "Google",
        isActive: isEditMode
          ? accountInfo?.googleSso || false
          : accountInfo?.googleSso !== false, // Maintain setting in edit mode, but for new accounts default to true when undefined
      },
      {
        name: "Microsoft",
        isActive: isEditMode
          ? accountInfo?.microsoftSso || false
          : accountInfo?.microsoftSso !== false, // Maintain setting in edit mode, but for new accounts default to true when undefined
      },
    ],
    isHealthcareOrg: accountInfo?.isHealthcareOrg || false,
  };

  const [customConstituentLimit, setCustomConstituentLimit] = useState(
    isCurrentConstituentLimit ? "" : constituentLimit
  );

  const MAX_CHAR = 255;
  const maxCharMessage = (chars) => `Max ${chars} characters`;

  const validationSchema = Yup.object({
    orgName: Yup.string()
      .required("Required")
      .min(2, "At least 2 characters")
      .max(MAX_CHAR, maxCharMessage(MAX_CHAR)),
    website: Yup.string()
      .url("Please enter a valid URL (ex. https://example.com)")
      .max(2000, maxCharMessage(2000)),
    firstName: isEditMode
      ? Yup.string().notRequired()
      : Yup.string().required("Required").max(MAX_CHAR, maxCharMessage(MAX_CHAR)),
    lastName: isEditMode
      ? Yup.string().notRequired()
      : Yup.string().required("Required").max(MAX_CHAR, maxCharMessage(MAX_CHAR)),
    email: isEditMode
      ? Yup.string().notRequired()
      : Yup.string()
          .email("Invalid email address")
          .required("Required")
          .max(254, maxCharMessage(254)),
    street: Yup.string().required("Required").max(MAX_CHAR, maxCharMessage(MAX_CHAR)),
    cityStateProvinceZip: Yup.string()
      .required("Required")
      .max(MAX_CHAR, maxCharMessage(MAX_CHAR)),
    country: Yup.string().required("Required").max(MAX_CHAR, maxCharMessage(MAX_CHAR)),
    imageSrc: Yup.string(),
    ssos: Yup.array().of(
      Yup.object({
        name: Yup.string(),
        isActive: Yup.boolean().required("Required"),
      })
    ),
    isHealthcareOrg: Yup.boolean(),
  });

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    trigger,
    formState: { errors, touchedFields, isValid, isSubmitted },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { fields } = useFieldArray({ control, name: "ssos" });
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  async function onSubmit(values) {
    if (isValid) {
      const account = {
        name: values.orgName,
        logo: values.imageSrc,
        primaryColor: values.primaryColor,
        secondaryColor: values.secondaryColor,
        address1: values.street,
        address2: values.cityStateProvinceZip,
        address3: values.country,
        website: values.website,
        googleSso: values.ssos[0].isActive,
        microsoftSso: values.ssos[1].isActive,
        contactLimit: parseInt(
          values.constituentLimit === "custom"
            ? customConstituentLimit || 0
            : values.constituentLimit
        ),
        videoLimit: values.isVideoStorageUnlimited === "true" ? MAX_INT32 : 1000,
        userLimit: parseInt(values.userLimit),
        isHealthcareOrg: values.isHealthcareOrg,
      };

      const user = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      };

      setLoading(true);
      try {
        const data = isEditMode
          ? await setAccount(accountUID, account)
          : pendingAccountId
          ? await createPendingAccount({ account, user, pendingAccountId })
          : await createAccount({ account, user });
        onSnackbar("success", `Account ${isEditMode ? "updated" : "created"}`);
        onComplete(data);
      } catch ({ data }) {
        onSnackbar(
          "error",
          `${isEditMode ? "Update" : "Create"} failed with Status:${data.status} - ${
            data.message
          }`
        );
      } finally {
        setLoading(false);
      }
    } else {
      onSnackbar("error", "Please fill out all required fields.");
    }
  }

  function onError(e) {
    containerRef.current.scrollIntoView();
    onSnackbar("error", "Please fill out all required fields.");
    console.log(e);
  }

  return (
    <div ref={containerRef} className={styles.card}>
      <div className={isEditMode || !accountUID ? "" : styles.disabled}>
        <p className={`${styles.subtext} ${styles.account_description}`}>
          Please fill out all the relevant information and click save.
        </p>
        <AccountDetailsForm
          isEditMode={isEditMode}
          control={control}
          labels={newAccountLabels}
          errors={errors}
          touchedFields={touchedFields}
          isSubmitted={isSubmitted}
        />
        <Divider className={styles.divider} />
        <Logo
          value={watch("imageSrc")}
          error={errors?.imageSrc?.message}
          touched={touchedFields?.imageSrc}
          setError={setError}
          isSubmitted={isSubmitted}
          clearErrors={clearErrors}
          onChange={setValue}
          trigger={trigger}
        />
        <Divider className={styles.divider} />
        <Colors
          onChange={setValue}
          primaryColor={watch("primaryColor")}
          secondaryColor={watch("secondaryColor")}
        />

        {!pendingAccountId && (
          <div>
            <Divider className={styles.divider} />
            <RadioSegment
              title="Constituent Limit:"
              description="Maximum number of contacts."
              control={control}
              name="constituentLimit"
              options={constituentLimits}
              customValue={customConstituentLimit}
              setCustomValue={setCustomConstituentLimit}
            />
            <Divider className={styles.divider} />
            {/* <RadioSegment
          title="Video Storage"
          description="Here is going to be text that explain a little about video storage."
          control={control}
          name="isVideoStorageUnlimited"
          options={[
            { label: "Unlimited", value: "true" },
            {
              label: "Limited",
              value: "false",
              tooltip: "This is a tooltip",
            },
          ]}
        /> */}
            {/* TODO Determine if this is neccessary or if we want this feature going forward MAKE A STORY
<Divider className={styles.divider} />
      <RadioSegment
        title="Email Sends"
        description="Here is going to be text that explain a little about email sends."
        control={control}
        name="emailLimit"
        options={emailLimits}
      />
      <Divider className={styles.divider} />
      <RadioSegment
        title="Templates"
        description="Here is going to be text that explain a little about templates."
        control={control}
        name="hasUnlimitedTemplates"
        options={unlimitedOptions}
      />
        <Divider className={styles.divider} />*/}
            <RadioSegment
              title="Team Members"
              description="Maximum number of users."
              control={control}
              name="userLimit"
              options={userLimits}
            />
            {/* TODO Determine if this is neccessary or if we want this feature going forward MAKE A STORY
      <Divider className={styles.divider} />
      <RadioSegment
        title="Video Requesting"
        description="Here is going to be text that explain a little about video requesting."
        control={control}
        name="hasVideoRequestingFeature"
        options={featureOptions}
      /> */}
            <Divider className={styles.divider} />
            <SsoSegment
              forms={fields}
              control={control}
              errors={errors?.ssos}
              isSubmitted={isSubmitted}
              touched={touchedFields?.ssos}
            />
            <Divider className={styles.divider} />
            <div className={styles.segment}>
              <Description
                title="Healthcare Organization"
                description="Check if PHI removal is required on all bulk uploads."
              />

              <div className={styles.segmentControls}>
                <FormControlLabel
                  className={styles.segmentControlLabel}
                  label="Is this a healthcare organization?"
                  control={
                    <Controller
                      control={control}
                      name="isHealthcareOrg"
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.button_wrapper}>
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            variant="contained"
            color="primary"
            disabled={loading}>
            {isEditMode ? "UPDATE ACCOUNT" : "CREATE NEW ACCOUNT"}
          </Button>
          {loading && <CircularProgress className={styles.button_progress} size={32} />}
        </div>
      </div>
    </div>
  );
};

AccountForm.propTypes = {
  pendingAccountId: PropTypes.string,
  accountUID: PropTypes.string,
  accountInfo: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSnackbar: PropTypes.func.isRequired,
};

export default AccountForm;
