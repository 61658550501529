import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import ReplyTo from "./replyTo";
import LocalPart from "./localPart";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import { joinStrings } from "util/helpers";

export default function EmailSettings(props) {
  const { accountInfo } = props;

  const [open, setOpen] = useState(false);
  const [customizeEmailBody, setCustomizeEmailBody] = useState(
    !!props.customizeEmailBody
  );
  const [customizeEmailFooter, setCustomizeEmailFooter] = useState(
    !!props.customEmailFooter
  );

  const {
    name = "Videos powered by Gratavid",
    address1 = "2815 Elliott Avenue",
    address2 = "Seattle, WA 98121",
    address3 = "",
  } = accountInfo;

  const emailFooter =
    props.customEmailFooter || joinStrings("\n", name, address1, address2, address3);

  const expansionPanelClass = open
    ? "expansionPanel-1000"
    : "expansionPanel-1000 collapsible";

  function toggleCustomizeEmailBody(checked) {
    setCustomizeEmailBody(checked);
    props.saveNoteState("emailBody", checked ? props.messageHtml : "");
  }

  function toggleCustomizeEmailFooter(checked) {
    setCustomizeEmailFooter(checked);
    props.saveNoteState("customEmailFooter", checked ? emailFooter : "");
  }

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <div className="col-12 row">
            <div className="col-12 row align-items-center">
              <h2 style={{ marginRight: "2px" }}>Email Settings</h2>
              <QuestionToLink id="emailSettings" desc="email settings" size="small" />
            </div>
            <div className="col-12">
              <span className="greyDesc">Email subject, body, and reply to</span>
            </div>
          </div>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div className="col-12" style={{ marginTop: "20px" }}>
          <TextInputToApi
            initialValue={props.emailSubject}
            multiline
            customContactProperties={props.customContactProperties}
            apiCall={props.saveNoteState}
            field="emailSubject"
            label="Email Subject"
            variant="standard"
          />
        </div>

        <div className="col-12" style={{ marginTop: "20px" }}>
          <TextInputToApi
            initialValue={props.watchNowText || "Watch Now"}
            apiCall={props.saveNoteState}
            field="watchNowText"
            label="Watch Now Button Text"
            variant="standard"
          />
        </div>

        {props.showEmailLocalPart && (
          <div className="col-12 row" style={{ marginTop: "30px" }}>
            <LocalPart
              accountInfo={accountInfo}
              emailLocalPart={props.emailLocalPart}
              domainLocalPart={accountInfo.domainLocalPart}
              domain={accountInfo.domain}
              passedSetState={props.saveNoteState}
            />
          </div>
        )}

        {props.showEmailReplyTo && (
          <div className="col-12" style={{ marginTop: "30px" }}>
            <ReplyTo
              accountInfo={accountInfo}
              saveNoteState={props.saveNoteState}
              replyToEmails={props.replyToEmails}
            />
          </div>
        )}

        <div className="col-12" style={{ marginTop: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customizeEmailBody}
                onChange={(e) => toggleCustomizeEmailBody(e.target.checked)}
              />
            }
            label="Customize Email Body"
          />
        </div>

        {customizeEmailBody && (
          <div className="col-12" style={{ margin: "15px 0px" }}>
            <TextInputToApi
              initialValue={props.emailBody}
              multiline
              customContactProperties={props.customContactProperties}
              apiCall={props.saveNoteState}
              field="emailBody"
              label="Email Body"
              variant="standard"
            />
          </div>
        )}

        <div className="col-12" style={{ marginTop: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customizeEmailFooter}
                onChange={(e) => toggleCustomizeEmailFooter(e.target.checked)}
              />
            }
            label="Customize Email Footer Address"
          />
        </div>

        {customizeEmailFooter && (
          <div className="col-12" style={{ margin: "15px 0px" }}>
            <TextInputToApi
              initialValue={emailFooter}
              multiline
              apiCall={props.saveNoteState}
              field="customEmailFooter"
              label="Email Footer Address"
              variant="standard"
            />
          </div>
        )}
      </div>
    </div>
  );
}

EmailSettings.propTypes = {
  accountInfo: PropTypes.object,
  customContactProperties: PropTypes.array,
  saveNoteState: PropTypes.func,
  emailBody: PropTypes.string,
  customizeEmailBody: PropTypes.bool,
  messageHtml: PropTypes.string,
  replyToEmails: PropTypes.array,
  customEmailFooter: PropTypes.string,
  emailLocalPart: PropTypes.string,
  showEmailReplyTo: PropTypes.bool,
  showEmailLocalPart: PropTypes.bool,
  watchNowText: PropTypes.string,
};
